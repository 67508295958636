/* @import url("https://fonts.cdnfonts.com/css/hello-honey-personal-use"); */
@import url("https://fonts.cdnfonts.com/css/antiqua-2");

@font-face {
  font-family: "hellohoney";
  src: url("fonts/hellohoney.otf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "HelloHoney";
  src: ("./fonts/hellohoney.otf");
} */

body {
  background-color: #eedfd3;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
